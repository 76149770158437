import { ProjectPromiseClient } from '../protos/squishy_grpc_web_pb';
import squishyPb from '../protos/squishy_pb';

import GrpcService, { getMetadata } from './grpc-service';
import RefreshAccessTokenInterceptor from './interceptors/refresh-access-token';

export default class ProjectService extends GrpcService {
  constructor(url) {
    super(ProjectPromiseClient, squishyPb, url, {
      unaryInterceptors: [new RefreshAccessTokenInterceptor(url)],
      subMessageTypes: {
        orderBy: 'ListTransactionsRequest.OrderBy',
        field: 'ListTransactionsRequest.OrderField',
        direction: 'Direction',
      },
    });
  }

  listAddresses(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('listAddresses', request, metadata);
  }

  addAddress(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('addAddress', request, metadata);
  }

  removeAddress(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('removeAddress', request, metadata);
  }

  listTransactions(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('listTransactions', request, metadata);
  }

  countTransactions(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('countTransactions', request, metadata);
  }
}
