import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import ListIcon from '@material-ui/icons/List';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SvgIcon from '@material-ui/core/SvgIcon';

const DISCORD_SERVER = 'https://discord.gg/FkKgwaU';
const YOUTUBE_CHANNEL = 'https://www.youtube.com/channel/UC5d9F6MqGqk2ty0NI-iTGJw';
const DOCUMENTATION = 'https://github.com/circle-free/fuelstop-docs';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
  closeButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: 'inherit',
  },
  active: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default function AppDrawer(props) {
  const { className, drawerOpen, handleClose, projects } = props;

  const classes = useStyles();

  const isActive = ({ isCurrent }) => ({
    className: clsx(classes.link, isCurrent && classes.active),
  });

  return (
    <Drawer
      ModalProps={{ onBackdropClick: handleClose }}
      classes={{ paper: className }}
      variant="temporary"
      anchor="left"
      open={drawerOpen}
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <Divider />
      {projects.length > 0 && (
        <React.Fragment>
          <Divider />
          <List>
            {projects.map(project => (
              <Link
                getProps={isActive}
                to={`/project/${project.id}`}
                onClick={handleClose}
                key={project.id}
              >
                <ListItem button>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary={project.chainName} />
                </ListItem>
              </Link>
            ))}
          </List>
        </React.Fragment>
      )}
      <Divider />
      <List>
        <ListItem button onClick={() => window.open(DOCUMENTATION)}>
          <ListItemIcon>
            <FindInPageIcon />
          </ListItemIcon>
          <ListItemText primary="Documentation" />
        </ListItem>
        <ListItem button disabled={false} onClick={() => window.open(YOUTUBE_CHANNEL)}>
          <ListItemIcon>
            <SvgIcon>
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 176 124"
              >
                <style>{'.cls-1{fill:red;}.cls-2{fill:#fff;}'}</style>
                <defs></defs>
                <path
                  className="cls-1"
                  d="M180.32,53.36A22.12,22.12,0,0,0,164.76,37.7C151,34,96,34,96,34s-55,0-68.76,3.7A22.12,22.12,0,0,0,11.68,53.36C8,67.18,8,96,8,96s0,28.82,3.68,42.64A22.12,22.12,0,0,0,27.24,154.3C41,158,96,158,96,158s55,0,68.76-3.7a22.12,22.12,0,0,0,15.56-15.66C184,124.82,184,96,184,96S184,67.18,180.32,53.36Z"
                  transform="translate(-8 -34)"
                />
                <polygon className="cls-2" points="70 88.17 116 62 70 35.83 70 88.17" />
              </svg>
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Learn" />
        </ListItem>
        <ListItem button disabled={false} onClick={() => window.open(DISCORD_SERVER)}>
          <ListItemIcon>
            <SvgIcon>
              <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 240">
                <style>{'.st0{fill:#7289DA;}'}</style>
                <path
                  className="st0"
                  d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"
                />
                <path
                  className="st0"
                  d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                />
              </svg>
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItem>
      </List>
    </Drawer>
  );
}
