import React from 'react';
import { Link } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { GoogleLogin } from 'react-google-login';

const useStyles = makeStyles(theme => ({
  title: {
    color: 'inherit',
    textDecoration: 'none',
    flexGrow: 1,
    textAlign: 'center',
  },
}));

export default function CustomAppBar({
  className,
  onMenuClicked,
  loggedIn,
  handleLogin,
  handleLogout,
}) {
  const classes = useStyles();

  return (
    <AppBar className={className} position="fixed">
      <Toolbar>
        <IconButton edge="start" onClick={onMenuClicked} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Link className={classes.title} to="/">
          <Typography variant="h4">fuelstop</Typography>
        </Link>
        {loggedIn ? (
          <IconButton edge="end" color="inherit" aria-label="logout" onClick={handleLogout}>
            <ExitToAppIcon />
          </IconButton>
        ) : (
          <div>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Sign in"
              onSuccess={handleLogin}
              onFailure={handleLogin}
              cookiePolicy={'single_host_origin'}
            />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
