import { UserPromiseClient } from '../protos/internal_grpc_web_pb';
import internalPb from '../protos/internal_pb';
import squishy from '../protos/squishy_pb';

import GrpcService, { getMetadata } from './grpc-service';
import RefreshAccessTokenInterceptor from './interceptors/refresh-access-token';

const chainNamesById = new Map(
  Object.entries(squishy.ChainName).map(([key, value]) => [value, key]),
);

export default class UserService extends GrpcService {
  constructor(url) {
    super(UserPromiseClient, { ...internalPb, squishy }, url, {
      unaryInterceptors: [new RefreshAccessTokenInterceptor(url)],
      subMessageTypes: {
        orderBy: 'ListAccountLedgersRequest.OrderBy',
        field: 'ListAccountLedgersRequest.OrderField',
        direction: 'squishy.Direction',
      },
    });
  }

  createSetupIntent(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('createSetupIntent', request, metadata);
  }

  deletePaymentMethod(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('deletePaymentMethod', request, metadata);
  }

  getAccount(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('getAccount', request, metadata);
  }

  getAccountEventsStream(request, callback) {
    const metadata = getMetadata();

    return this.execServerStream('getAccountEventsStream', request, metadata, {
      onData(account = {}) {
        callback(account);
      },
      onStatus() {},
      onEnd() {},
    }).cancel;
  }

  getPaymentMethodDetails(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('getPaymentMethodDetails', request, metadata);
  }

  listProjects(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('listProjects', request, metadata).then(({ projectsList }) => ({
      projectsList: projectsList.map(project =>
        Object.assign(project, { chainName: chainNamesById.get(project.chainName) }),
      ),
    }));
  }

  listAccountLedgers(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('listAccountLedgers', request, metadata);
  }

  countAccountLedgers(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('countAccountLedgers', request, metadata);
  }

  topUpAccount(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('topUpAccount', request, metadata);
  }

  updateAccountSettings(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('updateAccountSettings', request, metadata);
  }
}
