import LoginService from '../login-service';
import { StatusCode } from 'grpc-web';
import { setAuthToken, AUTHORIZATION_HEADER } from 'modules/grpc-service';

//TODO: put in config

export default class RefreshAccessTokenInterceptor {
  constructor(url) {
    this.loginService = new LoginService(url);
  }

  intercept(request, invoker) {
    return invoker(request).catch(err => {
      if (err.code !== StatusCode.PERMISSION_DENIED) {
        throw err;
      }

      return this.loginService
        .refreshAccessToken({})
        .then(({ accessToken }) => {
          setAuthToken(accessToken);

          const requestMetadata = request.getMetadata();

          Object.assign(requestMetadata, { [AUTHORIZATION_HEADER]: accessToken });

          return invoker(request);
        })
        .catch(refreshAccessTokenError => {
          console.error(
            `Failed to refresh the access token with error: ${err}`,
            refreshAccessTokenError,
          );

          throw err;
        });
    });
  }
}
