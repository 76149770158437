import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Chip from 'components/chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// FIXME: get these names from the enum definition
const statusName = status => ['Pending', 'Sent', 'Confirmed'][status];

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
  },
  cell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const TransactionRow = ({ chainName, transaction }) => {
  const classes = useStyles();
  const dateReceived = transaction.dateReceived
    ? new Date(transaction.dateReceived).toISOString()
    : '';

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Chip chainName={chainName} value={transaction.hash} tooltipOnValue={true} />
      </TableCell>
      <TableCell className={classes.cell}>
        <Chip chainName={chainName} value={transaction.from} tooltipOnValue={true} />
      </TableCell>
      <TableCell className={classes.cell}>
        {transaction.to ? (
          <Chip chainName={chainName} value={transaction.to} tooltipOnValue={true} />
        ) : (
          'Contract Creation'
        )}
      </TableCell>
      <TableCell className={classes.cell}>{dateReceived}</TableCell>
      <TableCell className={classes.cell}>{transaction.blockNumber || 'N/A'}</TableCell>
      <TableCell className={classes.cell}>{transaction.maxFeeInEther}</TableCell>
      <TableCell className={classes.cell}>{statusName(transaction.status)}</TableCell>
    </TableRow>
  );
};

export default function TransactionsTable({ chainName, transactions, emptyRows }) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="transactions table">
        <TableHead>
          <TableRow>
            <TableCell>Hash</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Received</TableCell>
            <TableCell style={{ width: '10%' }}>Block</TableCell>
            <TableCell style={{ width: '10%' }}>Max Fee</TableCell>
            <TableCell style={{ width: '10%' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map(t => (
            <TransactionRow chainName={chainName} key={t.hash} transaction={t} />
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
