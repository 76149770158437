import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';

import Chip from 'components/chip';
import ProgressIndicator from 'components/progressIndicator';

function AddAddressListItem({ add, error }) {
  const [address, setAddress] = useState('');

  return (
    <ListItem>
      <ListItemText style={{ marginRight: '2em' }}>
        <TextField
          variant="outlined"
          fullWidth
          color="secondary"
          label="Add Address"
          value={address}
          onChange={event => setAddress(event.target.value)}
          error={!!error}
          helperText={error && error.message}
        />
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton
          aria-label="add"
          onClick={() => add(address).then(success => success && setAddress(''))}
        >
          <AddCircleIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function AddressListItem({ chainName, address, remove }) {
  return (
    <ListItem>
      <ListItemText style={{ marginRight: '2em' }}>
        <Chip chainName={chainName} value={address} />
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton aria-label="remove" onClick={remove}>
          <DeleteForeverIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const addAddressWith = ({
  projectService,
  projectId,
  error,
  setError,
  addresses,
  setAddresses,
}) => address => {
  if (error !== null) setError(null);

  return (
    projectService
      .addAddress({ projectId, address })
      .catch(err => {
        console.error(err);
        throw new Error(`Invalid Address: "${address}"`);
      })
      // TODO: get a more-useful message from the API
      .then(({ success }) => {
        if (!success) throw new Error(`Address "${address}" already in address list`);

        setAddresses([...addresses, address]);

        return success;
      })
      .catch(setError)
  );
};

const removeAddress = ({
  projectService,
  projectId,
  error,
  setError,
  addresses,
  setAddresses,
  address,
}) => {
  if (error !== null) setError(null);

  projectService
    .removeAddress({ projectId, address })
    .then(({ success }) => {
      if (!success) throw new Error('Error: Failed to remove address');

      setAddresses(addresses.filter(a => a !== address));

      return success;
    })
    .catch(setError);
};

export default function Addresses({ project, projectService }) {
  const [addresses, setAddresses] = React.useState([]);
  const [ready, setReady] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (!project) return;

    projectService
      .listAddresses({ projectId: project.id })
      .then(res => res.addressesList || [])
      .then(setAddresses)
      .then(() => {
        if (!ready) setReady(true);
      });
  }, [projectService, project, ready]);

  return ready ? (
    <Grid container justify="center">
      <Grid item xs={12} md={6} component={List}>
        {addresses.map(address => (
          <AddressListItem
            chainName={project.chainName}
            key={address}
            address={address}
            remove={() =>
              removeAddress({
                projectService,
                projectId: project.id,
                error,
                setError,
                addresses,
                setAddresses,
                address,
              })
            }
          />
        ))}
        <AddAddressListItem
          add={addAddressWith({
            projectService,
            projectId: project.id,
            error,
            setError,
            addresses,
            setAddresses,
          })}
          error={error}
        />
      </Grid>
    </Grid>
  ) : (
    <ProgressIndicator />
  );
}
