import { LoginPromiseClient } from '../protos/internal_grpc_web_pb';
import internalPb from '../protos/internal_pb';

import GrpcService, { getMetadata, setAuthToken } from './grpc-service';

//TODO: put in config
const SESSION_COOKIE_NAME = 'session';

const clearSessionAuth = () => {
  document.cookie = `${SESSION_COOKIE_NAME}= ;path=/; domain=.fuelstop.io`;
  setAuthToken('');
};

export default class LoginService extends GrpcService {
  constructor(url) {
    super(LoginPromiseClient, internalPb, url);
  }

  googleAuthenticate(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('googleAuthenticate', request, metadata).then(({ accessToken }) => {
      setAuthToken(accessToken);
      return { accessToken };
    });
  }

  githubAuthenticate(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('githubAuthenticate', request, metadata);
  }

  refreshAccessToken(request) {
    const metadata = getMetadata();
    return this.execUnaryCall('refreshAccessToken', request, metadata).then(({ accessToken }) => {
      setAuthToken(accessToken);
      return { accessToken };
    });
  }

  invalidateRefreshToken(request) {
    clearSessionAuth();
    return this.execUnaryCall('invalidateRefreshToken', request);
  }
}
