import LoginService from './login-service';
import UserService from './user-service';
import ProjectService from './project-service';
import BlockService from './block-service';

export default function createApiServices(url) {
  return {
    loginService: new LoginService(url),
    userService: new UserService(url),
    projectService: new ProjectService(url),
    blockService: new BlockService(url),
  };
}
