import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import Details from './details';
import Balance from './balance';
import Addresses from './addresses';
import Transactions from './transactions';
import AccountHistory from './accountHistory';
import ProgressIndicator from 'components/progressIndicator';

const usePanelStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.light,
  },
}));

const Panel = ({ title, children, expanded, onChange, onRefresh }) => {
  const classes = usePanelStyles();

  return (
    <Accordion square expanded={expanded} onChange={onChange} className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" style={onRefresh ? { paddingRight: '10px' } : {}}>
          {title}
        </Typography>
        {onRefresh && (
          <IconButton
            color="inherit"
            onClick={e => {
              e.stopPropagation();
              onRefresh();
            }}
            style={{ padding: '0px' }}
          >
            <RefreshIcon fontSizeInherit={true} />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const AllOpenLayout = ({ panels, project, readyStates }) => {
  const [activePanels, setActivePanel] = React.useState(
    panels.reduce((activePanels, [title]) => Object.assign(activePanels, { [title]: true }), {}),
  );

  const togglePanel = title => (_event, isActive) => {
    setActivePanel({ ...activePanels, [title]: isActive });
  };

  return (
    <Container>
      {panels.map(([title, Component, onRefresh]) => (
        <Panel
          key={title}
          title={title}
          expanded={Boolean(project && activePanels[title])}
          onChange={togglePanel(title)}
          onRefresh={onRefresh}
        >
          <Component />
        </Panel>
      ))}
    </Container>
  );
};

const useProjectStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
}));

export default function Project({ projectsByIdMap, projectService, userService, projectId }) {
  const project = projectsByIdMap.get(projectId);

  const txsRef = React.useRef(null);
  const refreshTxs = () => txsRef.current.refresh();

  const historyRef = React.useRef(null);
  const refreshHistory = () => historyRef.current.refresh();

  const panels = [
    ['Balance', () => <Balance project={project} userService={userService} />],
    ['Details', () => <Details {...project} />],
    ['Addresses', () => <Addresses projectService={projectService} project={project} />],
    [
      'Transactions',
      () => <Transactions project={project} projectService={projectService} ref={txsRef} />,
      refreshTxs,
    ],
    [
      'Account History',
      () => <AccountHistory project={project} userService={userService} ref={historyRef} />,
      refreshHistory,
    ],
  ];

  const classes = useProjectStyles();
  return project ? (
    <div>
      <div className={classes.toolbar} />
      <AllOpenLayout panels={panels} project={project} />
    </div>
  ) : (
    <ProgressIndicator />
  );
}
