import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import copy from 'copy-text-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// Variables to control the truncation behavior
const startFixedChars = 4; // Number of chars before ellipsis - have priority over end chars
const endFixedChars = 4; // Number of chars after ellipsis  - lower priority than start chars
const fontFaceScaleFactor = 0.4; // Magic number dependent on font face - set by trial and error

// Derived from the 3 variables above
const startWidth = fontFaceScaleFactor * (startFixedChars + 3);
const endWidth = fontFaceScaleFactor * endFixedChars;

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    borderRadius: '100px',
    padding: '0 8px 0 8px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chipText: {
    flex: '1 1 0',
    minWidth: '10ch',
    padding: '8px 12px',
    textDecoration: 'none',
    color: 'inherit',
  },
  firstPart: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: `calc(100% - (1em * ${endWidth}))`,
    minWidth: startWidth,
    textOverflow: 'ellipsis',
  },
  lastPart: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: `calc(100% - (1em * ${startWidth}))`,
    direction: 'rtl',
  },
  copyButton: {
    flex: '0 0 0',
  },
}));

const COPY_TOOLTIP_TITLE_COPY = 'Copy';
const COPY_TOOLTIP_TITLE_COPIED = 'Copied';

const EXPLORER_LINKS = {
  MAINNET: 'https://etherscan.io',
  ROPSTEN: 'https://ropsten.etherscan.io',
  CLASSIC: 'https://blockscout.com/etc/mainnet',
};

const getExplorerLink = ({ chainName, value }) =>
  `${EXPLORER_LINKS[chainName]}/search?f=0&q=${value}`;

const getLink = ({ classes, chainName, value, firstPart, lastPart }) => (
  <a
    className={classes.chipText}
    href={getExplorerLink({ chainName, value })}
    rel="noreferrer"
    target="_blank"
  >
    <Typography className={classes.firstPart}>{firstPart}</Typography>
    <Typography className={classes.lastPart}>{lastPart}</Typography>
  </a>
);

const getValueComponent = ({ classes, chainName, value, tooltipOnValue }) => {
  const lastPart = value.slice(-4);
  const firstPart = value.substr(0, value.length - 3);

  const link = getLink({ classes, chainName, value, firstPart, lastPart });

  return tooltipOnValue ? (
    <Tooltip title={value} disableFocusListener disableTouchListener interactive>
      {link}
    </Tooltip>
  ) : (
    link
  );
};

const handleClickWith = ({ copy, setCopyTooltipTitle, value }) => event => {
  event.stopPropagation();
  copy(value);
  setCopyTooltipTitle(COPY_TOOLTIP_TITLE_COPIED);
};

export default function Chip(props) {
  const { className, value, chainName = 0, tooltipOnValue = false } = props;
  const classes = useStyles();
  const [copyTooltipTitle, setCopyTooltipTitle] = React.useState(COPY_TOOLTIP_TITLE_COPY);

  return (
    <div className={clsx(classes.root, className)}>
      {getValueComponent({ classes, chainName, value, tooltipOnValue })}
      <Tooltip
        title={copyTooltipTitle}
        onClose={() => setCopyTooltipTitle(COPY_TOOLTIP_TITLE_COPY)}
        disableFocusListener
        disableTouchListener
        interactive
      >
        <IconButton
          className={classes.copyButton}
          onClick={handleClickWith({ copy, setCopyTooltipTitle, value })}
          color="inherit"
        >
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
