import React from 'react';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';

import AccountLedgersTable from 'components/accountLedgersTable';
import ProgressIndicator from 'components/progressIndicator';

const INDEX_KEY = 'ledger_token';
const PAGE_KEY = 'ledger_page';
const PER_PAGE_KEY = 'ledger_per_page';
const PER_PAGE_OPTIONS_DEFAULT = 10;
const ORDER_BY = { field: 'CREATED_AT', direction: 'DESC' };
const PER_PAGE_OPTIONS = new Map([
  [PER_PAGE_OPTIONS_DEFAULT.toString(), PER_PAGE_OPTIONS_DEFAULT],
  ['25', 25],
  ['50', 50],
]);

const pushHistoryState = searchParams => {
  window.history.pushState(
    `${searchParams.get(PER_PAGE_KEY)}-${searchParams.get(INDEX_KEY)}`,
    null,
    `${window.location.pathname}?${searchParams.toString()}`,
  );
};

const getSavedPageParams = () => {
  const searchParams = new URL(window.location.href).searchParams;

  const page = Number(searchParams.get(PAGE_KEY) ?? 0);
  const pageSize = PER_PAGE_OPTIONS.get(searchParams.get(PER_PAGE_KEY)) || PER_PAGE_OPTIONS_DEFAULT;
  const pageToken = searchParams.get(INDEX_KEY) ?? '';

  return { page, pageSize, pageToken };
};

const savePageParams = update => {
  const searchParams = new URL(window.location.href).searchParams;

  if (Reflect.has(update, 'page')) searchParams.set(PAGE_KEY, update.page);
  if (Reflect.has(update, 'pageSize')) searchParams.set(PER_PAGE_KEY, update.pageSize);
  if (Reflect.has(update, 'pageToken')) searchParams.set(INDEX_KEY, update.pageToken);

  pushHistoryState(searchParams);
};

function AccountHistory({ project, userService }, ref) {
  const accountId = project.accountId;

  const [count, setCount] = React.useState(0);
  const [countReady, setCountReady] = React.useState(false);

  React.useEffect(() => {
    userService
      .countAccountLedgers({ accountId })
      .then(({ count }) => count)
      .then(setCount)
      .then(() => {
        if (!countReady) {
          setCountReady(true);
        }
      });
  }, [accountId, userService, countReady]);

  const [pageParams, setPageParams] = React.useState(getSavedPageParams);

  React.useImperativeHandle(ref, () => ({
    refresh: () => {
      const newPageParams = { pageToken: '', page: 0 };

      savePageParams(newPageParams);
      setPageParams(prevState => Object.assign({}, prevState, newPageParams));

      setCountReady(false);

      userService
        .countAccountLedgers({ accountId })
        .then(({ count }) => count)
        .then(setCount)
        .then(() => setCountReady(true));
    },
  }));

  const [accountLedgersData, setAccountLedgersdata] = React.useState({
    accountLedgers: [],
    nextPageToken: '',
    prevPageToken: '',
  });
  const [dataReady, setDataReady] = React.useState(false);

  React.useEffect(() => {
    const { pageToken, pageSize } = pageParams;

    userService
      .listAccountLedgers({
        accountId,
        orderBy: ORDER_BY,
        pageSize,
        pageToken,
      })
      .then(res => {
        if (!dataReady) {
          setDataReady(true);
        }
        if (!res?.accountLedgersList?.length) return;

        setAccountLedgersdata({
          accountLedgers: res.accountLedgersList,
          nextPageToken: res.nextPageToken,
          prevPageToken: res.prevPageToken,
        });
      });
  }, [accountId, pageParams, userService, dataReady]);

  const handleChangePage = (_event, newPage) => {
    const pageToken =
      accountLedgersData[newPage > pageParams.page ? 'nextPageToken' : 'prevPageToken'];

    const newPageParams = { page: newPage, pageToken, pageSize: pageParams.pageSize };

    savePageParams(newPageParams);
    setPageParams(newPageParams);
    setDataReady(false);
  };

  const handleChangeRowsPerPage = event => {
    const newPageParams = { pageSize: parseInt(event.target.value, 10), page: 0, pageToken: '' };

    savePageParams(newPageParams);
    setPageParams(newPageParams);
    setDataReady(false);
  };

  const emptyRows = accountLedgersData.accountLedgers.length ? 0 : 1;

  return dataReady && countReady ? (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <AccountLedgersTable
          accountLedgers={accountLedgersData.accountLedgers}
          emptyRows={emptyRows}
        />
        <TablePagination
          rowsPerPageOptions={[...PER_PAGE_OPTIONS.values()]}
          component="div"
          count={count}
          rowsPerPage={pageParams.pageSize}
          page={pageParams.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  ) : (
    <ProgressIndicator />
  );
}

export default React.forwardRef(AccountHistory);
