import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import copy from 'copy-text-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const API_HOST = process.env.REACT_APP_API_SERVICE_URL;

const onClickCopy = url => event => {
  event.stopPropagation();
  copy(url);
};

export default function Details({ id, chainName }) {
  const url = `${API_HOST}/json-rpc/${chainName.toLowerCase()}/${id}`;

  return (
    <List>
      <ListItem>
        <ListItemText primary="Network" secondary={chainName} />
      </ListItem>
      <ListItem>
        <ListItemText primary="RPC URL" secondary={url} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={onClickCopy(url)} color="inherit">
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}
