const noop = () => {};

const capitalize = str => str[0].toUpperCase() + str.slice(1);

const get = (obj, path, defaultValue = undefined) => {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

const messageDataToMessage = (service, messageName, messageData) => {
  const Message = get(service.pb, messageName);

  if (typeof Message === 'object') {
    return Message[messageData];
  }

  const message = new Message();

  for (const key in messageData) {
    const value = service.subMessageTypes[key]
      ? messageDataToMessage(service, service.subMessageTypes[key], messageData[key])
      : messageData[key];

    message[`set${capitalize(key)}`](value);
  }

  return message;
};

export const AUTHORIZATION_HEADER = 'authorization';
export const setAuthToken = token => sessionStorage.setItem(AUTHORIZATION_HEADER, token);
export const getMetadata = () => ({
  [AUTHORIZATION_HEADER]: sessionStorage.getItem(AUTHORIZATION_HEADER),
});

export default class GrpcService {
  constructor(GrpcPromiseClient, pb, url, options = {}) {
    const { unaryInterceptors, streamInterceptors, subMessageTypes = {} } = options;
    this.client = new GrpcPromiseClient(
      url,
      null,
      Object.assign(
        { suppressCorsPreflight: false, withCredentials: true },
        unaryInterceptors && { unaryInterceptors },
        streamInterceptors && { streamInterceptors },
      ),
    );
    this.pb = pb;
    this.subMessageTypes = subMessageTypes;
  }

  execUnaryCall(method, requestData, metadata) {
    const request = messageDataToMessage(this, `${capitalize(method)}Request`, requestData);

    return this.client[method](request, metadata).then(response => response.toObject());
  }

  execServerStream(method, requestData, metadata, callbacks = {}) {
    const request = messageDataToMessage(this, `${capitalize(method)}Request`, requestData);

    const stream = this.client[method](request, metadata);
    const onData = callbacks.onData || noop;

    stream.on('data', response => onData(response.toObject()));
    stream.on('status', callbacks.onStatus || noop);
    stream.on('end', callbacks.onEnd || noop);

    return { cancel: () => stream.cancel() };
  }
}
