import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from './appBar';
import AppDrawer from './appDrawer';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: 'max(25%, 200px)',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Navigation({ loggedIn, handleLogin, handleLogout, projects, refreshed }) {
  const classes = useStyles();

  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (loggedIn && !refreshed) {
      setDrawerIsOpen(true);
    }
  }, [loggedIn, refreshed]);

  return (
    <div>
      <AppBar
        className={classes.appBar}
        onMenuClicked={() => setDrawerIsOpen(!drawerIsOpen)}
        loggedIn={loggedIn}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
      />
      <AppDrawer
        className={classes.drawer}
        drawerOpen={drawerIsOpen}
        handleClose={() => setDrawerIsOpen(!drawerIsOpen)}
        projects={projects}
      />
    </div>
  );
}
