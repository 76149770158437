import React from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import './cardSection.css';
import poweredByStripe from 'resources/powered_by_stripe.svg';

// This page is based on the following guides:
// https://stripe.com/docs/payments/save-and-reuse
// https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

function AddCreditCard({ userService, account, disabled, setButtonsAreDisabled }) {
  const stripe = useStripe();
  const elements = useElements();
  const isLoading = !stripe || !elements;

  const [error, setError] = React.useState();

  const submit = async () => {
    setButtonsAreDisabled(true);

    const clientSecret = await userService
      .createSetupIntent({ accountId: account.id })
      .then(res => res?.clientSecret);

    if (!clientSecret) {
      setError(new Error('Could not retrieve client secret'));
      return;
    }

    if (isLoading) {
      setError(new Error('Stripe is not ready.'));
      return;
    }

    return stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      .then(res => {
        if (res?.error) {
          setError(res.error);
          setButtonsAreDisabled(false);
        }
      });
  };

  return (
    <Container maxWidth="sm">
      <Box marginTop={2}>
        <Typography variant="h6">Add a credit card</Typography>
      </Box>
      <Box marginTop={2}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </Box>
      <Box marginY={2}>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <a href="https://stripe.com" target="_blank" rel="noreferrer">
              <img src={poweredByStripe} alt="powered by stripe"></img>
            </a>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={disabled}
              error={error?.toString()}
              onClick={submit}
            >
              Add Card
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default function AddPaymentMethod({
  account,
  userService,
  setButtonsAreDisabled,
  buttonsAreDisabled,
}) {
  const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
  const stripePromise = React.useMemo(() => loadStripe(publicKey), [publicKey]);

  return (
    <Elements stripe={stripePromise}>
      <AddCreditCard
        account={account}
        userService={userService}
        disabled={buttonsAreDisabled}
        setButtonsAreDisabled={setButtonsAreDisabled}
      />
    </Elements>
  );
}
