import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// FIXME: get these names from the enum definition

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
  },
  cell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

// TODO: expose enum values from service
const SOURCE_TYPES = new Map([
  [0, 'Credit Card Payment'],
  [1, 'Blockchain Transaction'],
]);

const LedgerRow = ({ amount, action, sourceType, date }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        {action === 0 ? '-' : '+'} ${(Number(amount) / 100).toFixed(2)}
      </TableCell>
      <TableCell className={classes.cell}>{SOURCE_TYPES.get(sourceType)}</TableCell>
      <TableCell className={classes.cell}>{new Date(date).toISOString()}</TableCell>
    </TableRow>
  );
};

export default function AccountLedgersTable({ accountLedgers, emptyRows }) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="transactions table">
        <TableHead>
          <TableRow>
            <TableCell>Amount</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Timestamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accountLedgers.map(t => (
            <LedgerRow key={t.date} {...t} />
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
