import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HelpIcon from '@material-ui/icons/Help';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddPaymentMethod from 'components/addPaymentMethod';
import ProgressIndicator from 'components/progressIndicator';

const TICKERS = ['ETH', 'ETC'];

const formatAmount = amount => ((amount || 0) / 100).toFixed(2);

function BalanceButton({ action, buttonName, dialogContent, disabled, setButtonsAreDisabled }) {
  const [error, setError] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [modalButtonsAreDisabled, setModalButtonsAreDisabled] = React.useState(false);

  const handleButtonClick = () => {
    setOpen(true);
    setButtonsAreDisabled(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setButtonsAreDisabled(false);
  };

  const handleOkay = () => {
    setModalButtonsAreDisabled(true);
    return action()
      .then(res => {
        if (res?.success !== true) {
          throw new Error(`${buttonName} method was unsuccessful`);
        }
      })
      .catch(err => {
        console.error(err);
        setModalButtonsAreDisabled(false);
        setError(new Error('Something went wrong'));
      })
      .finally(() => {
        setOpen(false);
        setButtonsAreDisabled(false);
        setModalButtonsAreDisabled(false);
      });
  };

  return (
    <div>
      <Button
        variant="contained"
        disabled={disabled}
        error={error?.toString()}
        onClick={handleButtonClick}
      >
        {buttonName}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={handleCancel}
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Purchase'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" disabled={modalButtonsAreDisabled}>
            Cancel
          </Button>
          <Button onClick={handleOkay} color="primary" disabled={modalButtonsAreDisabled} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const removeCardFor = ({ userService, paymentMethodId }) => () =>
  userService.deletePaymentMethod({ paymentMethodId });

const topUpBalanceFor = ({ userService, accountId }) => () =>
  userService.topUpAccount({ accountId });

function PaymentMethod({
  account,
  paymentMethodDetails,
  userService,
  setButtonsAreDisabled,
  buttonsAreDisabled,
}) {
  const { brand, last4, id: paymentMethodId } = paymentMethodDetails;
  const {
    settings: { loadAmount, triggerBalance },
    isTest,
    id: accountId,
  } = account;

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} justify="space-between">
        <Grid item>
          <ListItem>
            <ListItemText primary="Card:" secondary={`${brand} ${last4}`} />
          </ListItem>
        </Grid>
        <Grid item>
          <ListItem>
            <ListItemText primary="Top Up Amount:" secondary={`$${formatAmount(loadAmount)}`} />
            <Tooltip
              title='The amount charged to your credit card every time you hit the "Top Up" button, or when your balance drops below the trigger balance. When the charge succeeds this amount is added to your balance.'
              disableFocusListener
              disableTouchListener
              interactive
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </Grid>
        <Grid item>
          <ListItem>
            <ListItemText
              primary="Trigger Balance:"
              secondary={`$${formatAmount(triggerBalance)}`}
            />
            <Tooltip
              title='When your account balance drops below the "Trigger Balance", your credit card will be charged and your account balance will increase by the "Top Up Amount".'
              disableFocusListener
              disableTouchListener
              interactive
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </Grid>
      </Grid>
      <Box marginY={2}>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <BalanceButton
              action={removeCardFor({ userService, paymentMethodId })}
              buttonName="Remove Card"
              dialogContent="You're credit card will be removed from this account. Is that okay?"
              setButtonsAreDisabled={setButtonsAreDisabled}
              disabled={isTest || buttonsAreDisabled}
            />
          </Grid>
          <Grid item></Grid>
          <Grid item>
            <BalanceButton
              action={topUpBalanceFor({ userService, accountId })}
              buttonName="Top Up"
              dialogContent="Your credit card will be charged $10.00 USD. Is that okay?"
              setButtonsAreDisabled={setButtonsAreDisabled}
              disabled={buttonsAreDisabled}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

function Payment({
  account,
  paymentMethodDetails,
  userService,
  setButtonsAreDisabled,
  buttonsAreDisabled,
}) {
  return paymentMethodDetails.id ? (
    <PaymentMethod
      account={account}
      paymentMethodDetails={paymentMethodDetails}
      userService={userService}
      setButtonsAreDisabled={setButtonsAreDisabled}
      buttonsAreDisabled={buttonsAreDisabled}
    />
  ) : (
    <AddPaymentMethod
      account={account}
      userService={userService}
      setButtonsAreDisabled={setButtonsAreDisabled}
      buttonsAreDisabled={buttonsAreDisabled}
    />
  );
}

export default function Balance({ project, userService }) {
  const [account, setAccount] = React.useState({});

  React.useEffect(() => {
    userService.getAccount({ accountId: project.accountId }).then(account => {
      setAccount(account);
    });
  }, [project, userService]);

  const [paymentMethodDetails, setPaymentMethodDetails] = React.useState({});

  React.useEffect(() => {
    if (!account.settings) {
      return;
    }

    if (account.settings.paymentMethodId === '' && paymentMethodDetails.id) {
      setPaymentMethodDetails({});
      return;
    }

    const hasNewPaymentMethod =
      account.settings.paymentMethodId &&
      account.settings.paymentMethodId !== paymentMethodDetails.id;
    if (hasNewPaymentMethod) {
      userService
        .getPaymentMethodDetails({ paymentMethodId: account.settings.paymentMethodId })
        .then(setPaymentMethodDetails);
      return;
    }
  }, [account, paymentMethodDetails, project, userService]);

  const [buttonsAreDisabled, setButtonsAreDisabled] = React.useState(false);

  React.useEffect(() => {
    const endStream = userService.getAccountEventsStream(
      { accountId: project.accountId },
      account => {
        setAccount(account);
        setButtonsAreDisabled(false);
      },
    );

    return endStream;
  }, [project, userService]);

  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    if (ready) {
      return;
    }

    const isAccountLoaded = Boolean(account.id);
    const isPaymentMethodDetailsLoaded = account.paymentMethodId
      ? Boolean(paymentMethodDetails.id)
      : true;

    if (isAccountLoaded && isPaymentMethodDetailsLoaded) {
      setReady(true);
    }
  }, [ready, account, paymentMethodDetails]);

  const coinBalance = account.balance ? account.balance / project.coinPrice : 0;
  const ticker = TICKERS[project?.ticker] || TICKERS[0];

  return ready ? (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" align="center">{`$${formatAmount(
          account.balance,
        )} USD`}</Typography>
        <Typography variant="body1" align="center">{`${coinBalance} ${ticker}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Payment
          account={account}
          paymentMethodDetails={paymentMethodDetails}
          userService={userService}
          setButtonsAreDisabled={setButtonsAreDisabled}
          buttonsAreDisabled={buttonsAreDisabled}
        />
      </Grid>
    </Grid>
  ) : (
    <ProgressIndicator />
  );
}
