export default {
  palette: {
    common: {
      highlight: '#ff4397',
      lowlight: '#690589',
    },
    primary: {
      main: '#fcc24c',
      dark: '#b28735',
      light: '#ffce6f',
    },
    secondary: {
      main: '#879dd7',
    },
    text: {
      primary: '#222222',
      secondary: '#555555',
    },
    background: {
      paper: '#f4efec',
      default: '#f4efec',
      light: '#f6f2ef',
      dark: '#aaa7a5',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    h1: {
      fontWeight: 400,
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#222222',
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#222222',
      },
    },
  },
};
