import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import ComputerIcon from '@material-ui/icons/Computer';
import Hidden from '@material-ui/core/Hidden';
import { GoogleLogin } from 'react-google-login';
import Link from '@material-ui/core/Link';

import gasStationSvg from '../resources/header.svg';
import diagramSvg from '../resources/diagram.svg';
import verticalDiagramSvg from '../resources/diagram_vertical.svg';

import carbonSvg from '../resources/carbon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    position: 'relative',
  },
  toolbar: theme.mixins.toolbar,
  header: {
    height: '80%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    borderBottom: `3px solid ${theme.palette.secondary.dark}`,
    padding: '5vw 5vw 5vw 5vw',
  },
  title: {
    color: theme.palette.common.highlight,
    textStroke: `2px ${theme.palette.common.lowlight}`,
    marginBottom: '0.5vw',
  },
  subtitle: {
    color: theme.palette.common.lowlight,
    marginBottom: '2vw',
  },
  pump: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  oddPanel: {
    backgroundColor: theme.palette.background.default,
    padding: '5vw 5vw 5vw 5vw',
    borderBottom: `3px solid ${theme.palette.secondary.dark}`,
  },
  panelTitle: {
    color: theme.palette.text.secondary,
    marginBottom: '2vw',
  },
  panelContent: {
    color: theme.palette.text.primary,
    textAlign: 'justify',
  },
  diagram: {
    width: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5vw',
  },
  evenPanel: {
    backgroundColor: theme.palette.secondary.main,
    padding: '5vw 5vw',
  },
  subCard: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: '2vw',
    height: '100%',
    position: 'relative',
  },
  cardIcon: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.highlight,
    marginBottom: '1vw',
  },
  cardText: {
    color: theme.palette.text.primary,
    marginTop: '1vw',
    justifyContent: 'center',
  },
  codeSnippet: {
    width: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5vw',
  },
  headerText: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  iconCredit: {
    backgroundColor: theme.palette.grey[800],
    color: 'white',
    padding: '5px 0',
  },
  link: {
    color: theme.palette.common.highlight,
  },
}));

export default function Landing({ handleLogin }) {
  const classes = useStyles();

  return (
    <div id="page-landing" className={classes.root}>
      <div className={classes.toolbar} />
      <Box className={classes.header}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={4}>
            <div className={classes.headerText}>
              <div>
                <div className={classes.title}>
                  <Typography variant="h1">Fuelstop</Typography>
                </div>
                <div className={classes.subtitle}>
                  <Typography variant="h4">
                    <i>your transactions</i>
                  </Typography>
                  <Typography variant="h4">
                    <i>our gas</i>
                  </Typography>
                </div>
                <GoogleLogin
                  render={renderProps => (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      Start Now
                    </Button>
                  )}
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  onSuccess={handleLogin}
                  onFailure={handleLogin}
                  cookiePolicy={'single_host_origin'}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.pump} src={gasStationSvg} alt="Gas Station Pump" />
          </Grid>
        </Grid>
      </Box>
      <div className={classes.oddPanel}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={8}>
            <div className={classes.subtitle}>
              <Typography variant="h5">
                <b>How It Works</b>
              </Typography>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div className={classes.panelTitle}>
                  <Typography variant="h4">
                    FuelStop enables users to pay their network fees with a credit-card.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <div className={classes.panelContent}>
                  <Typography variant="h6">
                    In order to successfully conduct a transaction or execute a contract on the
                    blockchain you need to pay gas in the form of an ETH fee. This means you have to
                    buy ETH, possibly through a crypto exchange which requires multiple steps to
                    sign up. Then you must hold this ETH and keep it safe, while always ensuring
                    your have enough for your transactions.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.panelContent}>
                  <Typography variant="h6">
                    Instead, you can send your signed transaction to fuelstop.io via the standard
                    JSON-RPC node interface. We will check your address to determine if it has
                    enough ETH to cover the transaction fees and top up your address if it doesn't.
                    Once your address receives the ETH, we'll send your transaction off to the
                    blockchain.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Hidden smDown>
              <img className={classes.diagram} src={diagramSvg} alt="How it works diagram" />
            </Hidden>
            <Hidden mdUp>
              <img
                className={classes.diagram}
                src={verticalDiagramSvg}
                alt="How it works diagram"
              />
            </Hidden>
          </Grid>
          <Grid item xs={12} md={2} />
        </Grid>
      </div>
      <div className={classes.evenPanel}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={4}>
            <div className={classes.panelContent}>
              <div className={classes.subtitle}>
                <Typography variant="h5">
                  <b>Simple To Use</b>
                </Typography>
              </div>
              <div className={classes.panelTitle}>
                <Typography variant="h4">We deal with the fees so you don't have to.</Typography>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('https://github.com/circle-free/fuelstop-docs')}
              >
                Read the Docs
              </Button>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card className={classes.subCard}>
                    <CardContent>
                      <Avatar className={classes.cardIcon}>
                        <DeveloperModeIcon />
                      </Avatar>
                      <div className={classes.panelContent}>
                        <Typography variant="h6">Easy Integration</Typography>
                      </div>
                      <div className={classes.cardText}>
                        <Typography variant="body1">
                          The nodes we provide have the standard node interface so you can use them
                          as a drop-in replacement in your workflow
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card className={classes.subCard}>
                    <CardContent>
                      <Avatar className={classes.cardIcon}>
                        <ComputerIcon />
                      </Avatar>
                      <div className={classes.panelContent}>
                        <Typography variant="h6">Beginner Friendly</Typography>
                      </div>
                      <div className={classes.cardText}>
                        <Typography variant="body1">
                          You can deploy and interact with smart contracts without ever having to
                          hold any ETH
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <img className={classes.codeSnippet} src={carbonSvg} alt="Code example" />
          </Grid>
          <Grid item xs={12} md={1} />
        </Grid>
      </div>

      <Typography className={classes.iconCredit} align="center">
        Icons made by
        <Link
          className={classes.link}
          href="https://www.flaticon.com/authors/flat-icons"
          target="_blank"
          rel="noopener"
        >
          {' '}
          Flat Icons{' '}
        </Link>
        and
        <Link
          className={classes.link}
          href="https://www.flaticon.com/authors/freepik"
          target="_blank"
          rel="noopener"
        >
          {' '}
          Freepik{' '}
        </Link>
        from
        <Link
          className={classes.link}
          href="https://www.flaticon.com/"
          target="_blank"
          rel="noopener"
        >
          {' '}
          www.flaticon.com{' '}
        </Link>
      </Typography>
    </div>
  );
}
