/**
 * @fileoverview gRPC-Web generated client stub for internal
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var squishy_pb = require('./squishy_pb.js')
const proto = {};
proto.internal = require('./internal_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.internal.BlockClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.internal.BlockPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.GetTransactionsRequest,
 *   !proto.internal.GetTransactionsResponse>}
 */
const methodDescriptor_Block_GetTransactions = new grpc.web.MethodDescriptor(
  '/internal.Block/GetTransactions',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.internal.GetTransactionsRequest,
  proto.internal.GetTransactionsResponse,
  /**
   * @param {!proto.internal.GetTransactionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.GetTransactionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.GetTransactionsRequest,
 *   !proto.internal.GetTransactionsResponse>}
 */
const methodInfo_Block_GetTransactions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.GetTransactionsResponse,
  /**
   * @param {!proto.internal.GetTransactionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.GetTransactionsResponse.deserializeBinary
);


/**
 * @param {!proto.internal.GetTransactionsRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.internal.GetTransactionsResponse>}
 *     The XHR Node Readable Stream
 */
proto.internal.BlockClient.prototype.getTransactions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/internal.Block/GetTransactions',
      request,
      metadata || {},
      methodDescriptor_Block_GetTransactions);
};


/**
 * @param {!proto.internal.GetTransactionsRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.internal.GetTransactionsResponse>}
 *     The XHR Node Readable Stream
 */
proto.internal.BlockPromiseClient.prototype.getTransactions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/internal.Block/GetTransactions',
      request,
      metadata || {},
      methodDescriptor_Block_GetTransactions);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.internal.LoginClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.internal.LoginPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.GoogleAuthenticateRequest,
 *   !proto.internal.AuthenticateResponse>}
 */
const methodDescriptor_Login_GoogleAuthenticate = new grpc.web.MethodDescriptor(
  '/internal.Login/GoogleAuthenticate',
  grpc.web.MethodType.UNARY,
  proto.internal.GoogleAuthenticateRequest,
  proto.internal.AuthenticateResponse,
  /**
   * @param {!proto.internal.GoogleAuthenticateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.AuthenticateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.GoogleAuthenticateRequest,
 *   !proto.internal.AuthenticateResponse>}
 */
const methodInfo_Login_GoogleAuthenticate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.AuthenticateResponse,
  /**
   * @param {!proto.internal.GoogleAuthenticateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.AuthenticateResponse.deserializeBinary
);


/**
 * @param {!proto.internal.GoogleAuthenticateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.AuthenticateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.AuthenticateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.LoginClient.prototype.googleAuthenticate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.Login/GoogleAuthenticate',
      request,
      metadata || {},
      methodDescriptor_Login_GoogleAuthenticate,
      callback);
};


/**
 * @param {!proto.internal.GoogleAuthenticateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.AuthenticateResponse>}
 *     Promise that resolves to the response
 */
proto.internal.LoginPromiseClient.prototype.googleAuthenticate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.Login/GoogleAuthenticate',
      request,
      metadata || {},
      methodDescriptor_Login_GoogleAuthenticate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.GithubAuthenticateRequest,
 *   !proto.internal.AuthenticateResponse>}
 */
const methodDescriptor_Login_GithubAuthenticate = new grpc.web.MethodDescriptor(
  '/internal.Login/GithubAuthenticate',
  grpc.web.MethodType.UNARY,
  proto.internal.GithubAuthenticateRequest,
  proto.internal.AuthenticateResponse,
  /**
   * @param {!proto.internal.GithubAuthenticateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.AuthenticateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.GithubAuthenticateRequest,
 *   !proto.internal.AuthenticateResponse>}
 */
const methodInfo_Login_GithubAuthenticate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.AuthenticateResponse,
  /**
   * @param {!proto.internal.GithubAuthenticateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.AuthenticateResponse.deserializeBinary
);


/**
 * @param {!proto.internal.GithubAuthenticateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.AuthenticateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.AuthenticateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.LoginClient.prototype.githubAuthenticate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.Login/GithubAuthenticate',
      request,
      metadata || {},
      methodDescriptor_Login_GithubAuthenticate,
      callback);
};


/**
 * @param {!proto.internal.GithubAuthenticateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.AuthenticateResponse>}
 *     Promise that resolves to the response
 */
proto.internal.LoginPromiseClient.prototype.githubAuthenticate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.Login/GithubAuthenticate',
      request,
      metadata || {},
      methodDescriptor_Login_GithubAuthenticate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.RefreshAccessTokenRequest,
 *   !proto.internal.RefreshAccessTokenResponse>}
 */
const methodDescriptor_Login_RefreshAccessToken = new grpc.web.MethodDescriptor(
  '/internal.Login/RefreshAccessToken',
  grpc.web.MethodType.UNARY,
  proto.internal.RefreshAccessTokenRequest,
  proto.internal.RefreshAccessTokenResponse,
  /**
   * @param {!proto.internal.RefreshAccessTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.RefreshAccessTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.RefreshAccessTokenRequest,
 *   !proto.internal.RefreshAccessTokenResponse>}
 */
const methodInfo_Login_RefreshAccessToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.RefreshAccessTokenResponse,
  /**
   * @param {!proto.internal.RefreshAccessTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.RefreshAccessTokenResponse.deserializeBinary
);


/**
 * @param {!proto.internal.RefreshAccessTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.RefreshAccessTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.RefreshAccessTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.LoginClient.prototype.refreshAccessToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.Login/RefreshAccessToken',
      request,
      metadata || {},
      methodDescriptor_Login_RefreshAccessToken,
      callback);
};


/**
 * @param {!proto.internal.RefreshAccessTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.RefreshAccessTokenResponse>}
 *     Promise that resolves to the response
 */
proto.internal.LoginPromiseClient.prototype.refreshAccessToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.Login/RefreshAccessToken',
      request,
      metadata || {},
      methodDescriptor_Login_RefreshAccessToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.InvalidateRefreshTokenRequest,
 *   !proto.internal.InvalidateRefreshTokenResponse>}
 */
const methodDescriptor_Login_InvalidateRefreshToken = new grpc.web.MethodDescriptor(
  '/internal.Login/InvalidateRefreshToken',
  grpc.web.MethodType.UNARY,
  proto.internal.InvalidateRefreshTokenRequest,
  proto.internal.InvalidateRefreshTokenResponse,
  /**
   * @param {!proto.internal.InvalidateRefreshTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.InvalidateRefreshTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.InvalidateRefreshTokenRequest,
 *   !proto.internal.InvalidateRefreshTokenResponse>}
 */
const methodInfo_Login_InvalidateRefreshToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.InvalidateRefreshTokenResponse,
  /**
   * @param {!proto.internal.InvalidateRefreshTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.InvalidateRefreshTokenResponse.deserializeBinary
);


/**
 * @param {!proto.internal.InvalidateRefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.InvalidateRefreshTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.InvalidateRefreshTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.LoginClient.prototype.invalidateRefreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.Login/InvalidateRefreshToken',
      request,
      metadata || {},
      methodDescriptor_Login_InvalidateRefreshToken,
      callback);
};


/**
 * @param {!proto.internal.InvalidateRefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.InvalidateRefreshTokenResponse>}
 *     Promise that resolves to the response
 */
proto.internal.LoginPromiseClient.prototype.invalidateRefreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.Login/InvalidateRefreshToken',
      request,
      metadata || {},
      methodDescriptor_Login_InvalidateRefreshToken);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.internal.UserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.internal.UserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.CreateSetupIntentRequest,
 *   !proto.internal.CreateSetupIntentResponse>}
 */
const methodDescriptor_User_CreateSetupIntent = new grpc.web.MethodDescriptor(
  '/internal.User/CreateSetupIntent',
  grpc.web.MethodType.UNARY,
  proto.internal.CreateSetupIntentRequest,
  proto.internal.CreateSetupIntentResponse,
  /**
   * @param {!proto.internal.CreateSetupIntentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.CreateSetupIntentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.CreateSetupIntentRequest,
 *   !proto.internal.CreateSetupIntentResponse>}
 */
const methodInfo_User_CreateSetupIntent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.CreateSetupIntentResponse,
  /**
   * @param {!proto.internal.CreateSetupIntentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.CreateSetupIntentResponse.deserializeBinary
);


/**
 * @param {!proto.internal.CreateSetupIntentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.CreateSetupIntentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.CreateSetupIntentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.createSetupIntent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/CreateSetupIntent',
      request,
      metadata || {},
      methodDescriptor_User_CreateSetupIntent,
      callback);
};


/**
 * @param {!proto.internal.CreateSetupIntentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.CreateSetupIntentResponse>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.createSetupIntent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/CreateSetupIntent',
      request,
      metadata || {},
      methodDescriptor_User_CreateSetupIntent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.DeletePaymentMethodRequest,
 *   !proto.internal.DeletePaymentMethodResponse>}
 */
const methodDescriptor_User_DeletePaymentMethod = new grpc.web.MethodDescriptor(
  '/internal.User/DeletePaymentMethod',
  grpc.web.MethodType.UNARY,
  proto.internal.DeletePaymentMethodRequest,
  proto.internal.DeletePaymentMethodResponse,
  /**
   * @param {!proto.internal.DeletePaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.DeletePaymentMethodResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.DeletePaymentMethodRequest,
 *   !proto.internal.DeletePaymentMethodResponse>}
 */
const methodInfo_User_DeletePaymentMethod = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.DeletePaymentMethodResponse,
  /**
   * @param {!proto.internal.DeletePaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.DeletePaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.internal.DeletePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.DeletePaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.DeletePaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.deletePaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/DeletePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_User_DeletePaymentMethod,
      callback);
};


/**
 * @param {!proto.internal.DeletePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.DeletePaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.deletePaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/DeletePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_User_DeletePaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.GetAccountRequest,
 *   !proto.internal.GetAccountResponse>}
 */
const methodDescriptor_User_GetAccount = new grpc.web.MethodDescriptor(
  '/internal.User/GetAccount',
  grpc.web.MethodType.UNARY,
  proto.internal.GetAccountRequest,
  proto.internal.GetAccountResponse,
  /**
   * @param {!proto.internal.GetAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.GetAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.GetAccountRequest,
 *   !proto.internal.GetAccountResponse>}
 */
const methodInfo_User_GetAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.GetAccountResponse,
  /**
   * @param {!proto.internal.GetAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.GetAccountResponse.deserializeBinary
);


/**
 * @param {!proto.internal.GetAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.GetAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.GetAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.getAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/GetAccount',
      request,
      metadata || {},
      methodDescriptor_User_GetAccount,
      callback);
};


/**
 * @param {!proto.internal.GetAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.GetAccountResponse>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.getAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/GetAccount',
      request,
      metadata || {},
      methodDescriptor_User_GetAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.GetAccountEventsStreamRequest,
 *   !proto.internal.GetAccountResponse>}
 */
const methodDescriptor_User_GetAccountEventsStream = new grpc.web.MethodDescriptor(
  '/internal.User/GetAccountEventsStream',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.internal.GetAccountEventsStreamRequest,
  proto.internal.GetAccountResponse,
  /**
   * @param {!proto.internal.GetAccountEventsStreamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.GetAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.GetAccountEventsStreamRequest,
 *   !proto.internal.GetAccountResponse>}
 */
const methodInfo_User_GetAccountEventsStream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.GetAccountResponse,
  /**
   * @param {!proto.internal.GetAccountEventsStreamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.GetAccountResponse.deserializeBinary
);


/**
 * @param {!proto.internal.GetAccountEventsStreamRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.internal.GetAccountResponse>}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.getAccountEventsStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/internal.User/GetAccountEventsStream',
      request,
      metadata || {},
      methodDescriptor_User_GetAccountEventsStream);
};


/**
 * @param {!proto.internal.GetAccountEventsStreamRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.internal.GetAccountResponse>}
 *     The XHR Node Readable Stream
 */
proto.internal.UserPromiseClient.prototype.getAccountEventsStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/internal.User/GetAccountEventsStream',
      request,
      metadata || {},
      methodDescriptor_User_GetAccountEventsStream);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.GetPaymentMethodDetailsRequest,
 *   !proto.internal.PaymentDetails>}
 */
const methodDescriptor_User_GetPaymentMethodDetails = new grpc.web.MethodDescriptor(
  '/internal.User/GetPaymentMethodDetails',
  grpc.web.MethodType.UNARY,
  proto.internal.GetPaymentMethodDetailsRequest,
  proto.internal.PaymentDetails,
  /**
   * @param {!proto.internal.GetPaymentMethodDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.PaymentDetails.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.GetPaymentMethodDetailsRequest,
 *   !proto.internal.PaymentDetails>}
 */
const methodInfo_User_GetPaymentMethodDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.PaymentDetails,
  /**
   * @param {!proto.internal.GetPaymentMethodDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.PaymentDetails.deserializeBinary
);


/**
 * @param {!proto.internal.GetPaymentMethodDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.PaymentDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.PaymentDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.getPaymentMethodDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/GetPaymentMethodDetails',
      request,
      metadata || {},
      methodDescriptor_User_GetPaymentMethodDetails,
      callback);
};


/**
 * @param {!proto.internal.GetPaymentMethodDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.PaymentDetails>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.getPaymentMethodDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/GetPaymentMethodDetails',
      request,
      metadata || {},
      methodDescriptor_User_GetPaymentMethodDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.ListProjectsRequest,
 *   !proto.internal.ListProjectsReponse>}
 */
const methodDescriptor_User_ListProjects = new grpc.web.MethodDescriptor(
  '/internal.User/ListProjects',
  grpc.web.MethodType.UNARY,
  proto.internal.ListProjectsRequest,
  proto.internal.ListProjectsReponse,
  /**
   * @param {!proto.internal.ListProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.ListProjectsReponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.ListProjectsRequest,
 *   !proto.internal.ListProjectsReponse>}
 */
const methodInfo_User_ListProjects = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.ListProjectsReponse,
  /**
   * @param {!proto.internal.ListProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.ListProjectsReponse.deserializeBinary
);


/**
 * @param {!proto.internal.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.ListProjectsReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.ListProjectsReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.listProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/ListProjects',
      request,
      metadata || {},
      methodDescriptor_User_ListProjects,
      callback);
};


/**
 * @param {!proto.internal.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.ListProjectsReponse>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.listProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/ListProjects',
      request,
      metadata || {},
      methodDescriptor_User_ListProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.ListAccountLedgersRequest,
 *   !proto.internal.ListAccountLedgersResponse>}
 */
const methodDescriptor_User_ListAccountLedgers = new grpc.web.MethodDescriptor(
  '/internal.User/ListAccountLedgers',
  grpc.web.MethodType.UNARY,
  proto.internal.ListAccountLedgersRequest,
  proto.internal.ListAccountLedgersResponse,
  /**
   * @param {!proto.internal.ListAccountLedgersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.ListAccountLedgersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.ListAccountLedgersRequest,
 *   !proto.internal.ListAccountLedgersResponse>}
 */
const methodInfo_User_ListAccountLedgers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.ListAccountLedgersResponse,
  /**
   * @param {!proto.internal.ListAccountLedgersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.ListAccountLedgersResponse.deserializeBinary
);


/**
 * @param {!proto.internal.ListAccountLedgersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.ListAccountLedgersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.ListAccountLedgersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.listAccountLedgers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/ListAccountLedgers',
      request,
      metadata || {},
      methodDescriptor_User_ListAccountLedgers,
      callback);
};


/**
 * @param {!proto.internal.ListAccountLedgersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.ListAccountLedgersResponse>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.listAccountLedgers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/ListAccountLedgers',
      request,
      metadata || {},
      methodDescriptor_User_ListAccountLedgers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.CountAccountLedgersRequest,
 *   !proto.internal.CountAccountLedgersResponse>}
 */
const methodDescriptor_User_CountAccountLedgers = new grpc.web.MethodDescriptor(
  '/internal.User/CountAccountLedgers',
  grpc.web.MethodType.UNARY,
  proto.internal.CountAccountLedgersRequest,
  proto.internal.CountAccountLedgersResponse,
  /**
   * @param {!proto.internal.CountAccountLedgersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.CountAccountLedgersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.CountAccountLedgersRequest,
 *   !proto.internal.CountAccountLedgersResponse>}
 */
const methodInfo_User_CountAccountLedgers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.CountAccountLedgersResponse,
  /**
   * @param {!proto.internal.CountAccountLedgersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.CountAccountLedgersResponse.deserializeBinary
);


/**
 * @param {!proto.internal.CountAccountLedgersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.CountAccountLedgersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.CountAccountLedgersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.countAccountLedgers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/CountAccountLedgers',
      request,
      metadata || {},
      methodDescriptor_User_CountAccountLedgers,
      callback);
};


/**
 * @param {!proto.internal.CountAccountLedgersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.CountAccountLedgersResponse>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.countAccountLedgers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/CountAccountLedgers',
      request,
      metadata || {},
      methodDescriptor_User_CountAccountLedgers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.TopUpAccountRequest,
 *   !proto.internal.TopUpAccountResponse>}
 */
const methodDescriptor_User_TopUpAccount = new grpc.web.MethodDescriptor(
  '/internal.User/TopUpAccount',
  grpc.web.MethodType.UNARY,
  proto.internal.TopUpAccountRequest,
  proto.internal.TopUpAccountResponse,
  /**
   * @param {!proto.internal.TopUpAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.TopUpAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.TopUpAccountRequest,
 *   !proto.internal.TopUpAccountResponse>}
 */
const methodInfo_User_TopUpAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.TopUpAccountResponse,
  /**
   * @param {!proto.internal.TopUpAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.TopUpAccountResponse.deserializeBinary
);


/**
 * @param {!proto.internal.TopUpAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.TopUpAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.TopUpAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.topUpAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/TopUpAccount',
      request,
      metadata || {},
      methodDescriptor_User_TopUpAccount,
      callback);
};


/**
 * @param {!proto.internal.TopUpAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.TopUpAccountResponse>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.topUpAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/TopUpAccount',
      request,
      metadata || {},
      methodDescriptor_User_TopUpAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.internal.UpdateAccountSettingsRequest,
 *   !proto.internal.UpdateAccountSettingsResponse>}
 */
const methodDescriptor_User_UpdateAccountSettings = new grpc.web.MethodDescriptor(
  '/internal.User/UpdateAccountSettings',
  grpc.web.MethodType.UNARY,
  proto.internal.UpdateAccountSettingsRequest,
  proto.internal.UpdateAccountSettingsResponse,
  /**
   * @param {!proto.internal.UpdateAccountSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.UpdateAccountSettingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.internal.UpdateAccountSettingsRequest,
 *   !proto.internal.UpdateAccountSettingsResponse>}
 */
const methodInfo_User_UpdateAccountSettings = new grpc.web.AbstractClientBase.MethodInfo(
  proto.internal.UpdateAccountSettingsResponse,
  /**
   * @param {!proto.internal.UpdateAccountSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.internal.UpdateAccountSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.internal.UpdateAccountSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.internal.UpdateAccountSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.internal.UpdateAccountSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.internal.UserClient.prototype.updateAccountSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/internal.User/UpdateAccountSettings',
      request,
      metadata || {},
      methodDescriptor_User_UpdateAccountSettings,
      callback);
};


/**
 * @param {!proto.internal.UpdateAccountSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.internal.UpdateAccountSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.internal.UserPromiseClient.prototype.updateAccountSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/internal.User/UpdateAccountSettings',
      request,
      metadata || {},
      methodDescriptor_User_UpdateAccountSettings);
};


module.exports = proto.internal;

