import React from 'react';
import { Router } from '@reach/router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import configTheme from './configs/theme';
import createApiServices from './modules/api';
import Navigation from './components/navigation';
import Project from './pages/project';
import Landing from './pages/landing';

const muiTheme = createMuiTheme(configTheme);

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: muiTheme.palette.primary.main,
    zIndex: muiTheme.zIndex.drawer + 1,
  },
  drawer: {
    width: 'max(25%, 200px)',
    backgroundColor: muiTheme.palette.primary.main,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  spacerBar: {
    flex: '0',
    position: 'relative',
  },
  router: {
    flex: '1',
    position: 'relative',
    minHeight: 0,
  },
  dialogTitle: {
    textAlign: 'center',
  },
}));

const { loginService, userService, projectService } = createApiServices(
  process.env.REACT_APP_API_SERVICE_URL,
);

const handleLoginWith = ({ setLoggedIn }) => async response => {
  try {
    if (response.error) {
      throw response.error;
    }

    const { id_token: token } = response.getAuthResponse();

    return loginService.googleAuthenticate({ token }).then(() => {
      setLoggedIn(true);
    });
  } catch (err) {
    console.error(err);
    setLoggedIn(false);
  }
};

const handleLogout = () => {
  loginService
    .invalidateRefreshToken({})
    .then(success => {
      if (!success) throw new Error('Logout was not successful');
    })
    .catch(err => {
      // TODO: handle logout error
      console.error(err);
    })
    .finally(() => {
      window.location = '/';
    });
};

export default function App() {
  const classes = useStyles(muiTheme);

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [refreshed, setRefreshed] = React.useState(false);

  React.useEffect(() => {
    loginService
      .refreshAccessToken({})
      .then(() => {
        setRefreshed(true);
        setLoggedIn(true);
      })
      .catch(() => {
        if (window.location.pathname !== '/') {
          window.location = '/';
        }
      });
  }, []);

  const [projects, setProjects] = React.useState([]);

  React.useEffect(() => {
    if (!loggedIn) {
      setProjects([]);

      return;
    }

    userService.listProjects({}).then(({ projectsList = [] }) => setProjects(projectsList));
  }, [loggedIn, setProjects]);

  const projectsByIdMap = new Map(projects.map(project => [project.id, project]));

  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Navigation
        loggedIn={loggedIn}
        handleLogin={handleLoginWith({ setLoggedIn })}
        handleLogout={() => {
          setLoggedIn(false);
          return handleLogout();
        }}
        projects={projects}
        refreshed={refreshed}
      />
      <div className={classes.container}>
        <Router className={classes.router}>
          <Landing default path="/" handleLogin={handleLoginWith({ setLoggedIn })} />
          <Project
            path="/project/:projectId"
            projectService={projectService}
            userService={userService}
            projectsByIdMap={projectsByIdMap}
          />
        </Router>
      </div>
    </MuiThemeProvider>
  );
}
